import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=3f90ce7e&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=3f90ce7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f90ce7e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Column: require('/opt/build/repo/components/layout/Column.vue').default})
